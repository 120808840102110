body {
	background-image: url('/20150605113755/assets/images/p6.png');
	font-family: Roboto, sans-serif;
	color: #28302e;
	font-size: 16px;
	line-height: 24px;
}

#printHeader,
#printFooter {
	display: none;
}

.main-div {
	background-color: #fff;
}

h1 {
	margin-top: 20px;
	margin-bottom: 10px;
	font-size: 38px !important;
	line-height: 44px;
	font-weight: 700;
}

h2 {
	margin-top: 20px;
	margin-bottom: 10px;
	font-size: 32px !important;
	line-height: 36px;
	font-weight: 700;
}

h3 {
	margin-bottom: 10px;
	font-size: 24px !important;
	line-height: 30px;
	font-weight: 700;
}

h4 {
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 18px !important;
	line-height: 24px;
	font-weight: 700;
}

h5 {
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 14px !important;
	line-height: 20px;
	font-weight: 700;
}

h6 {
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 12px !important;
	line-height: 18px;
	font-weight: 700;
}

p {
	margin-bottom: 5px;
}

a {
	color: #00e;
}

.button,
.primary,
.secondary,
.tertiary,
button {
	display: inline-block;
	margin-right: 10px;
	padding: 9px 25px !important;
	background-color: black;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	color: white;
	font-size: 18px;
	font-weight: 400;
	text-align: center;
	text-decoration: none;
	font-weight: normal !important;
}

.button.primary,
.primary,
button,
a.primary,
.content-div a.primary {
	background-color: #459368;
	border: 0 none !important;
	color: #fff !important;
}

.button.primary:hover,
.primary:hover,
button:hover {
	background-color: #006a53 !important;
}

.button.primary:active,
.primary:active,
button:active {
	background-color: #82bb86 !important;
}

.button.secondary,
.secondary {
	padding: 7px 23px !important;
	border: 2px solid #459368 !important;
	background-color: white;
	color: #459368 !important;
}

.button.secondary:hover,
.secondary:hover {
	background-color: #d2e7d3 !important;
}

.button.secondary:active,
.secondary:active {
	background-color: #84b086 !important;
}

.button.tertiary,
.tertiary {
	padding: 8px 24px !important;
	border: 1px solid #459368 !important;
	background-color: white;
	color: #459368 !important;
}

.button.tertiary:hover,
.tertiary:hover {
	background-color: #d2e7d3 !important;
}

.button.tertiary:active,
.tertiary:active {
	background-color: #84b086 !important;
}

.header-section {
	height: 150px;
}

.rotator-section {
	margin-bottom: 20px;
}

.footer-section {
	height: 250px;
	margin-top: 40px;
}

.nav-bar {
	background-color: transparent;
}

.rotator {
	height: 356px;
}


.home-cb-top {
	width: 20%;
	position: relative;
}

.home-cb-top + .home-cb-top {
	margin-left: 0.875rem;
}

.darken-div {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	opacity: 0.15;
	-webkit-transition: opacity 300ms ease;
	transition: opacity 300ms ease;
}

.home-cb-top-link {
	height: 200px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.home-cb-top-link:hover .darken-div {
	opacity: 0;
}

.product-tagline-p {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}

.product-tagline-p p {
	margin-bottom: 0;
}

.product-tagline-p img {
	height: auto;
	width: 100%;
	max-width: none;
}

.licensing-column {
	width: 45%;
}

.analytical-column {
	width: 55%;
}

.slide-nav {
	display: inline-block;
}

.rotator-arrow {
	display: none;
}

.slide-1,
.w-slide {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.analytical-div {
	height: 400px;
	background-image: url('/20150605113755/assets/images/analytical.jpg');
	background-position: 80% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.analytical-caption {
	width: 75%;
	height: 100%;
	padding: 20px;
	float: right;
	background-color: rgba(255, 255, 255, 0.9);
}

.hero-caption-div {
	height: 100%;
	max-width: 90%;
	min-width: 70%;
	padding: 30px;
	float: right;
	background-color: rgba(255, 255, 255, 0.85);
	text-align: center;
}

.caption-div-inner {
	display: flex;
	width: 100%;
	flex-direction: column;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.caption-div-inner {
		display: block;
	}

	.hero-caption-p {
		display: block !important;
		clear: both;
		float: none !important;
	}
}

.logo-a {
	margin-top: 50px;
}

.nav-container {
	padding-right: 18px;
	padding-left: 18px;
}

.dropdown-toggle {
	margin-right: 12px;
	margin-left: 12px;
	padding-right: 0px;
	padding-bottom: 16px;
	padding-left: 0px;
	border-bottom-color: transparent;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #707070;
}

.dropdown-toggle:hover {
	padding-bottom: 5px;
	border-bottom: 2px solid #459368;
	color: #28302e;
}

.nav-link-a {
	margin-right: 12px;
	margin-left: 12px;
	padding-right: 0px;
	padding-bottom: 16px;
	padding-left: 0px;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #707070;
	font-family: Arial, sans-serif;
	font-size: 14px;
	line-height: 20px;
}

#GRIPFooterLogo {
	padding-top: 0px !important;
	margin-top: -13px;
}

.breadcrumbs-div ol {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	display: inline-block;
	width: 100%;
}

.breadcrumbs-div ol li {
	display: inline;
	margin: 0px;
	padding: 0px;
}

.main-div {
	min-height: 500px;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.nav-link-a {
		margin-right: 6px;
		margin-left: 6px;
	}

	.nav-container {
		padding-left: 0;
		padding-right: 0;
	}
}

.nav-link-a:hover {
	padding-bottom: 5px;
	border-bottom: 2px solid #459368;
	color: #28302e;
}

.nav-link-a.home {
	display: none;
}

.dropdown-arrow {
	display: none;
}

.nav-menu {
	margin-top: 55px;
}

.hero-title {
	font-family: Roboto, sans-serif;
	color: #00693c;
	font-size: 52px;
	line-height: 62px;
	font-weight: 300;
	text-transform: uppercase;
	text-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px;
	max-width: 750px;
	margin: 0 auto;
}

.divider {
	display: block;
	width: 135px;
	height: 2px;
	margin: 20px auto;
	background-color: #459368;
}

.hero-caption-p {
	color: #71645c;
	font-size: 23px;
	line-height: 32px;
	font-weight: 300;
	text-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px;
	display: inline-block;
	margin-right: auto;
	margin-left: auto;
}

.hero-learn-more {
	display: inline-block;
	margin-top: 10px;
	padding-right: 25px;
	background-image: url('/20150605113755/assets/images/arrow-green.png');
	background-position: 100% 50%;
	background-repeat: no-repeat;
	color: #00693c;
	font-size: 18px;
	font-weight: 400;
	text-decoration: none;
}

.hero-learn-more:hover {
	color: #459368;
}

.betaatp-a {
	position: relative;
	display: block;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	color: rgba(255, 255, 255, 0.2);
	font-size: 41px;
	line-height: 41px;
	font-weight: 700;
	text-transform: uppercase;
}

.betaatp-a:hover {
	color: rgba(255, 255, 255, 0.85);
}

.betator-a {
	position: relative;
	display: block;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	color: rgba(255, 255, 255, 0.2);
	font-size: 41px;
	line-height: 41px;
	font-weight: 700;
	text-transform: uppercase;
}

.betator-a:hover {
	color: rgba(255, 255, 255, 0.85);
}

.hmb-a {
	position: relative;
	display: block;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	color: rgba(255, 255, 255, 0.2);
	font-size: 41px;
	line-height: 41px;
	font-weight: 700;
	text-transform: uppercase;
}

.hmb-a:hover {
	color: rgba(255, 255, 255, 0.85);
}

.licensing-a {
	width: 100%;
	height: 200px;
	padding: 20px;
	background-image: url('/20150605113755/assets/images/products-green.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	text-decoration: none;
}

.licensing-a:hover {
	background-image: url('/20150605113755/assets/images/products-50.jpg');
	background-size: cover;
	background-repeat: no-repeat;
}

.licensing-p {
	display: inline-block;
	padding-right: 29px;
	background-image: url('/20150605113755/assets/images/arrow-white.png');
	background-position: 100% 50%;
	background-repeat: no-repeat;
	font-family: Roboto, sans-serif;
	color: white;
	font-size: 20px;
	line-height: 26px;
	font-weight: 300;
	text-transform: none;
}

.licensing-title {
	margin-top: 10px;
	margin-bottom: 5px;
	font-family: Roboto, sans-serif;
	color: white;
	font-size: 56px;
	line-height: 60px;
	font-weight: 300;
	text-transform: uppercase;
	text-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px;
}

.analytical-title,
.analytical-title a {
	margin-top: 10px;
	color: #00693c;
	font-size: 40px;
	line-height: 48px;
	font-weight: 300;
	text-align: center;
	text-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px;
}

.analytical-title a {
	text-decoration: none;
	cursor: auto;
}

.analytical-subtitle {
	margin-bottom: 15px;
	color: #00693c;
	font-size: 20px !important;
	text-align: center;
}

.analytical-a {
	display: block;
	padding-right: 25px;
	float: right;
	background-image: url('/20150605113755/assets/images/arrow-green.png');
	background-position: 100% 50%;
	background-repeat: no-repeat;
	color: #00693c;
	font-size: 18px;
	text-decoration: none;
}

.analytical-a:hover {
	color: #459368;
}

.analytical-p,
.analytical-caption p,
.analytical-caption div {
	margin-bottom: 10px;
}

.copyright-p {
	margin-bottom: 30px;
	color: #707070;
	font-size: 14px;
}

.disclaimer-p {
	color: #707070;
	font-size: 0.75rem;
	line-height: 1rem;
}

.footer-a {
	color: #707070;
	font-size: 14px;
	text-decoration: none;
}

.footer-a:hover {
	color: #459368;
}

.footer-a-divider {
	display: inline;
	color: #707070;
	font-size: 14px;
}

.grip-logo {
	display: block;
	margin-top: 20px;
	float: right;
}

#GRIPFooterLogoText {
	margin-bottom: -10px;
	font-weight: bold;
}

.footer-links {
	text-align: right;
}

.news-div {
	height: 180px;
	margin-top: 20px;
	padding-bottom: 20px;
	background-color: rgba(69, 147, 104, 0.09);
}

.news-title,
.news-title a {
	margin-top: 0px;
	margin-bottom: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 20px;
	font-family: Roboto, sans-serif;
	color: white;
	font-size: 24px;
	font-weight: 300;
	text-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px;
}

.news-title {
	background-color: rgba(69, 147, 104, 0.74);
}

.news-title a {
	text-decoration: none;
	cursor: auto;
	padding-left: 0;
}

.news-item-div {
	margin-right: 20px;
	margin-bottom: 15px;
	margin-left: 20px;
}

.news-date {
	width: 25%;
	float: left;
	font-size: 14px !important;
}

.news-title-a {
	display: block;
	width: 75%;
	margin-bottom: 15px;
	float: left;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #00693c;
	font-size: 14px;
	line-height: 22px;
	text-decoration: none;
}

.news-title-a:hover {
	color: #459368;
}

.all-news-a {
	margin-top: 20px;
	margin-right: 20px;
	float: right;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: white;
	font-size: 14px;
	text-decoration: none;
}

.all-news-a:hover {
	color: #00693c;
}

.content-section {
	margin-top: 20px;
}

.breadcrumbs-div {
	height: auto;
	margin-left: 20px;
	color: #707070;
	font-size: 14px;
	padding-top: 1rem;
}

.breadcrumbs-a {
	color: #00693c;
	text-decoration: none;
}

.breadcrumbs-a:hover {
	color: #84b086;
}

.breadcrumbs-divider {
	display: inline;
	margin-right: 8px;
	margin-left: 8px;
}

.breadcrumbs-current {
	display: inline;
}

.content-div {
	width: 90%;
	margin-bottom: 30px;
	padding-left: 20px;
}

.h1,
h1 {
	margin-top: 0px;
	color: black;
	font-size: 36px;
	line-height: 44px;
}

.intro-p {
	color: #00693c;
	font-size: 18px;
	line-height: 30px;
	font-style: italic;
	font-weight: 400;
}

.p,
.content-div p,
.right-sidebar-column p {
	color: black;
	font-size: 16px;
	line-height: 24px;
}

.h2,
h2 {
	color: black;
	font-size: 30px;
	line-height: 36px;
}

.a,
.content-div a,
.right-sidebar-column a,
.cms_link {
	color: #00693c !important;
	font-weight: 700;
	text-decoration: none;
}

.a:hover,
.content-div a:hover,
.right-sidebar-column a:hover,
.cms_link:hover {
	padding-top: 4px;
	padding-bottom: 4px;
	background-color: #d2e7d3;
}

.a:active,
.content-div a:active,
.right-sidebar-column a:active,
.cms_link:active {
	background-color: transparent;
	color: #a3afa3;
}

.h3,
h3 {
	color: black;
	font-size: 22px;
	line-height: 26px;
}

.ul,
ul {
	color: black;
	font-size: 16px;
	line-height: 26px;
}

.h4,
h4 {
	color: black;
	font-size: 18px;
	line-height: 22px;
}

.h5,
h5 {
	color: black;
	font-size: 16px;
	line-height: 20px;
}

.left-nav-div {
	margin-top: 50px;
	padding-right: 30px;
	border-right: 1px solid #bcbdc0;
	font-size: 15px;
}

.left-nav-a {
	display: block;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 8px;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #00693c;
	text-decoration: none;
}

.left-nav-a:hover {
	background-color: #aeccbb;
}

.left-nav-a.w--current,
.left-nav-a.active {
	background-color: #d2e7d3;
}

.left-nav-sub-nav-div {
	padding-left: 20px;
	font-size: 14px;
}

.float-right-img {
	position: relative;
	left: 70px;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-left: -50px;
	float: right;
}

.right-sidebar-column {
	margin-top: 50px;
	padding-right: 15px;
	padding-left: 15px;
	border-left: 1px solid #bcbdc0;
}

.right-sidebar-title {
	margin-top: 10px;
	font-size: 18px;
	line-height: 26px;
}

.right-sidebar-p {
	font-size: 14px;
	line-height: 22px;
}

.right-sidebar-a {
	color: #00693c;
	font-size: 14px;
	font-weight: 700;
	text-decoration: none;
}

.right-sidebar-a:hover {
	background-color: #d2e7d3;
}

.right-sidebar-a:active {
	background-color: transparent;
	color: #a3afa3;
}

html.w-mod-js *[data-ix="homepage-slide-up"] {
	opacity: 0;
	-webkit-transform: translate(0px, 30px);
	-ms-transform: translate(0px, 30px);
	transform: translate(0px, 30px);
}

@media screen and (min-width: 1200px) {
	.w-container {
		max-width: 1170px;
	}
}

@media (max-width: 1200px) {
	.betaatp-a {
		font-size: 30px;
		line-height: 30px;
	}

	.betator-a {
		font-size: 30px;
		line-height: 30px;
	}

	.hmb-a {
		font-size: 30px;
		line-height: 30px;
	}
}

@media (max-width: 991px) {
	.home-cb-top {
		padding-bottom: 20px;
	}

	.header-section {
		height: 100px;
	}

	.analytical-div {
		height: 450px;
	}

	.hero-caption-div {
		max-width: 80%;
		min-width: 60%;
	}

	.logo-a {
		width: 360px;
		margin-top: 20px;
	}

	.dropdown-toggle {
		margin-right: 0px;
		margin-bottom: 2px;
		margin-left: 0px;
		padding-right: 20px;
		padding-bottom: 20px;
		padding-left: 20px;
		-webkit-transition-property: none;
		transition-property: none;
		-webkit-transition-timing-function: ease;
		transition-timing-function: ease;
		color: white;
		font-size: 20px;
	}

	.dropdown-toggle:hover {
		padding: 20px;
		border-bottom: 0px none transparent;
		background-color: rgba(0, 0, 0, 0.2);
		color: white;
	}

	.nav-link-a {
		width: 100%;
		max-width: 991px;
		margin-right: 0px;
		margin-left: 0px;
		padding: 20px;
		color: white;
		font-size: 20px;
	}

	.nav-link-a.has-submenu {
		width: 80%;
	}

	.nav-link-a:hover {
		padding: 20px;
		border-bottom: 0px none transparent;
		background-color: rgba(0, 0, 0, 0.2);
		color: white;
	}

	.nav-link-a.home {
		display: block;
	}

	.dropdown-arrow {
		display: block;
		width: 20%;
		height: 100%;
		margin-right: 0px;
		padding-top: 18px;
		background-color: rgba(0, 0, 0, 0.2);
		font-size: 30px;
		text-align: center;
	}

	.nav-menu {
		margin-top: 0px;
		background-color: #00693c;
		color: white;
	}

	.hero-title {
		font-size: 36px;
		line-height: 42px;
	}

	.hero-caption-p {
		font-size: 16px;
	}

	.betaatp-a {
		background-position: 89% 0px;
		background-size: cover;
		font-size: 45px;
		line-height: 45px;
	}

	.betator-a {
		font-size: 45px;
		line-height: 45px;
	}

	.hmb-a {
		font-size: 45px;
		line-height: 45px;
	}

	.analytical-p,
	.analytical-caption p,
	.analytical-caption div {
		font-size: 14px;
	}

	.copyright-p {
		text-align: center;
	}

	.disclaimer-p {
		margin-bottom: 20px;
		text-align: center;
	}

	.grip-logo {
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	#GRIPFooterLogo {
		margin: 0 auto;
		margin-top: -15px;
	}

	.footer-links {
		text-align: center;
	}

	.menu-button {
		color: #00693c;
		font-size: 40px;
	}

	.menu-button.w--open {
		background-color: #00693c;
	}

	.dropdown {
		max-width: 991px;
		margin-right: 0px;
		margin-left: 0px;
	}

	.dropdown-list.w--open {
		background-color: #459368;
	}

	.dropdown-a {
		padding-top: 20px;
		padding-bottom: 20px;
		padding-left: 40px;
		color: white;
	}

	.dropdown-a:hover {
		background-color: rgba(0, 0, 0, 0.09);
		text-decoration: none;
	}

	.news-div {
		height: 230px;
	}

	.news-date,
	#news_module .cms_list_item .cms_metadata2.cms_date h3 {
		width: 30%;
		float: none;
	}

	.news-title-a,
	#news_module .cms_list_item .cms_metadata1.cms_title h3 a {
		width: auto;
		float: none;
	}

	.left-nav-sub-nav-div {
		padding-left: 15px;
	}

	.dropdown-2 {
		width: 100%;
		max-width: 100%;
		margin-right: 0px;
		margin-left: 0px;
	}

	.dropdown-list-2.w--open {
		margin-left: 40px;
		background-color: #84b086;
	}

	.home-cb-top-link {
		height: 150px;
	}

	.home-cb-top + .home-cb-top {
		margin-left: 0.575rem;
	}

	.product-tagline-p p {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.product-tagline-p img {
		width: 125%;
	}

	.nav-container {
		padding-right: 0px;
		padding-left: 10px;
	}

	.right-sidebar-column {
		margin-top: 20px;
		padding-top: 10px;
		padding-bottom: 20px;
		border-top: 1px solid #bcbdc0;
		border-bottom: 1px solid #bcbdc0;
		border-left-style: none;
		border-left-width: 0px;
		width: 100%;
	}

	.main-container {
		width: 100%;
	}

	.breadcrumbs-div {
		width: 100%;
	}

	.main-container-inner {
		width: 100%;
		padding-right: 10px;
		padding-left: 10px;
	}

	.left-nav-div {
		display: none;
	}
}

@media (max-width: 767px) {
	.footer-section {
		height: auto;
		padding-bottom: 40px;
	}

	.rotator {
		height: auto;
	}

	.licensing-column {
		width: 100%;
	}

	.analytical-column {
		width: 100%;
		margin-top: 25px;
	}

	.analytical-div {
		height: 300px;
	}

	.hero-caption-div {
		width: 100%;
		max-width: 767px;
		min-width: 0%;
	}

	.hero-title {
		font-size: 34px;
		line-height: 36px;
	}

	.licensing-a {
		height: 200px;
	}

	.analytical-p._2,
	.analytical-caption div:last-of-type {
		display: none;
	}

	.copyright-p {
		text-align: center;
	}

	.disclaimer-p {
		margin-bottom: 20px;
		text-align: center;
	}

	.grip-logo {
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.footer-links {
		text-align: center;
	}

	.news-div {
		height: auto;
	}

	.news-date,
	#news_module .cms_list_item .cms_metadata2.cms_date h3 {
		width: auto;
	}

	.content-div {
		width: 100%;
		padding-right: 10px;
		padding-left: 10px;
	}

	.left-nav-div {
		display: none;
	}

	.float-right-img {
		position: static;
		margin-left: 10px;
	}

	.home-cb-top-link {
		height: 125px;
	}

	.product-tagline-p img {
		width: 100%;
	}

	.home-cb-top {
		width: 100%;
		margin-left: 0;
		height: 271px;
		margin-bottom: 10px;
	}

	.home-cb-top + .home-cb-top {
		margin-left: 0;
	}

	.home-cb-top-link {
		height: 271px;
	}

	.product-tagline-p,
	.product-tagline-p p {
		display: block;
		text-align: center;
		height: 100%;
	}

	.product-tagline-p img {
		height: 100%;
		width: auto;
	}
}

@media (max-width: 479px) {
	.home-cb-top {
		padding-bottom: 0px;
	}

	img {
		float: none;
		clear: both;
	}

	.button {
		display: block;
		margin-right: 0px;
		margin-bottom: 10px;
	}

	.button.primary {
		display: block;
	}

	.header-section {
		height: 75px;
	}

	.rotator {
		height: auto;
	}

	.analytical-caption {
		width: 100%;
		height: auto;
		padding: 15px;
	}

	.hero-caption-div {
		padding: 20px;
	}

	.logo-a {
		width: 225px;
	}

	.nav-container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.hero-title {
		font-size: 23px;
		line-height: 28px;
	}

	.hero-caption-p {
		font-size: 14px;
		line-height: 26px;
	}

	.betaatp-a {
		font-size: 50px;
		line-height: 50px;
	}

	.betator-a {
		font-size: 50px;
		line-height: 50px;
	}

	.hmb-a {
		font-size: 50px;
		line-height: 50px;
	}

	.licensing-a {
		padding: 10px;
	}

	.analytical-title,
	.analytical-title a {
		font-size: 36px;
		line-height: 36px;
	}

	.menu-button {
		padding: 10px;
	}

	.news-date,
	#news_module .cms_list_item .cms_metadata2.cms_date h3 {
		width: auto;
	}

	.breadcrumbs-div {
		margin-left: 10px;
	}

	.content-div {
		margin-bottom: 15px;
		padding-bottom: 20px;
		border-bottom: 1px solid #a3afa3;
	}

	.home-cb-top {
		height: 190px;
	}

	.home-cb-top-link {
		height: 190px;
	}
}

/** mmenu **/
/*scroll bars*/
html.mm-opened body {
	overflow: auto !important;
}

.mm-opened {
	overflow: visible !important;
}

.mm-menu.mm-offcanvas {
	position: absolute !important;
}

/*aplying required margin top*/
html.mm-opened .mm-menu.mm-offcanvas {
	margin-top: 86px;
	position: absolute;
}

@media (max-width: 479px) {
	html.mm-opened .mm-menu.mm-offcanvas {
		margin-top: 70px;
		position: absolute;
	}
}

@media (max-width: 330px) {
	html.mm-opened .mm-menu.mm-offcanvas {
		margin-top: 127px;
		position: absolute;
	}
}

/*main menu styles*/
.mm-menu.mm-front {
	box-shadow: none;
}

.mm-menu.mm-front.mm-top,
.mm-menu.mm-front.mm-bottom {
	height: auto;
}

.mm-menu,
.mm-menu > .mm-panel {
	height: auto;
}

.mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults)::after {
	border-bottom: 0 none;
}

.mm-list > li > a,
.mm-list > li > span {
	padding: 20px;
}

.mm-menu .mm-list {
	padding-top: 0;
}

.mm-list > li > a,
.mm-list > li > span {
	color: #ffffff;
}

/*styling arrow*/
.mm-list a.mm-subopen + a,
.mm-list a.mm-subopen + span {
	margin-right: 60px;
}

.mm-list a.mm-subopen {
	width: 20% !important;
	background-color: rgba(0, 0, 0, 0.2) !important;
	height: 100% !important;
}

.mm-list a.mm-subopen::after {
	right: 26px !important;
}

.mm-menu .mm-list > li > a.mm-subopen::before {
	border: 0 none !important;
}

.mm-list a.mm-subopen::after,
.mm-list a.mm-subclose::before {
	width: 13px;
	height: 13px;
}

.mm-list a.mm-subopen::after {
	border-right: medium none;
	border-left: 4px solid #ffffff;
	border-bottom: 4px solid #ffffff;
}

.mm-menu.mm-vertical .mm-list > li > a.mm-subopen::after {
	left: 46%;
	top: 33%;
}

.mm-menu .mm-list > li > a.mm-subopen::after,
.mm-menu .mm-list > li > a.mm-subclose::before {
	border-color: rgba(255, 255, 255, 1);
}

.mm-menu.mm-vertical .mm-list > li.mm-opened > a.mm-subopen::after {
	transform: rotate(-45deg);
}

.mm-menu .mm-list > li.mm-opened > a.mm-subopen {
	height: 60px !important;
}

.mm-menu.mm-vertical .mm-list .mm-panel {
	padding: 0;
}

.mm-menu.mm-vertical .mm-list li.mm-opened > a.mm-subopen,
.mm-menu.mm-vertical .mm-list li.mm-opened > ul {
	background-color: #459368;
}

/*styling second level submenu*/
.mm-list li ul > li > a {
	padding: 20px 20px 17px 40px;
	font-family: Roboto, sans-serif;
	font-size: 16px;
	line-height: 24px;
}

.mm-list li ul > li > a:hover {
	padding: 20px 20px 17px 40px;
	background-color: rgba(0, 0, 0, 0.09);
}

/*styling third level submenu*/
.mm-menu.mm-vertical .mm-list li.mm-opened li.mm-opened ul {
	padding: 0px 0px 0px 40px;
}

.mm-menu.mm-vertical .mm-list li.mm-opened li.mm-opened ul li {
	background-color: #84b086;
}

/*styling inside pages*/
/*messages*/
.success {
	margin-bottom: 0px;
	font-family: Roboto, sans-serif;
	color: #44a82d;
	font-size: 16px;
	line-height: 22px;
	font-weight: 700;
	margin-bottom: 20px;
	padding: 10px 20px;
	border-style: solid;
	border-width: 3px 1px 1px;
	border-color: #44a82d;
}

.success p,
.success ul li {
	font-family: Roboto, sans-serif;
	color: #44a82d;
	font-size: 16px;
	line-height: 22px;
	font-weight: 700;
}

.error {
	margin-bottom: 0px;
	font-family: Roboto, sans-serif;
	color: #b40805;
	font-size: 16px;
	line-height: 22px;
	font-weight: 700;
	margin-bottom: 20px;
	padding: 10px 20px;
	border-style: solid;
	border-width: 2px 1px 1px;
	border-color: #b40805;
}

.error p,
.error ul li {
	font-family: Roboto, sans-serif;
	color: #b40805;
	font-size: 16px;
	line-height: 22px;
	font-weight: 700;
}

/*tables*/
table.styled {
	width: 100%;
	margin: 10px 0 10px 0;
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border: none;
}

table.styled thead th {
	font-size: 18px;
	color: white;
	background-color: #006a53;
	padding: 10px 10px;
	text-align: left;
}

table.styled th {
	font-weight: normal;
	font-size: 16px;
	padding: 10px 10px;
	background-color: #cddfcf;
	color: #006a53;
	text-align: left;
}

table.styled td {
	padding: 8px 10px;
	color: #231f20;
}

table.styled.striped tr:nth-child(even) {
	background: #e0e0e0;
}

table.styled.striped tr:nth-child(odd) {
	background: #eeeeee;
}

table.styled th a {
	color: inherit !important;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
}

table.styled th a:hover {
	color: #fff;
	background-color: transparent;
	text-decoration: none;
}


/*styling module pages*/
/*news page*/

.archive_links a,
.archive_links a.selected {
	padding-bottom: 4px !important;
	padding-top: 4px !important;
}

.right-sidebar-column .box {
	margin-bottom: 20px;
}

.right-sidebar-column .box:last-of-type {
	margin-bottom: 0px;
}

.right-sidebar-column .contentbox_item .contentbox_item_content {
	width: 80% !important;
}

@media (max-width: 991px) {
	.right-sidebar-column .contentbox_item .contentbox_item_content {
		width: 95% !important;
	}
}

@media (max-width: 550px) {
	.right-sidebar-column .contentbox_item .contentbox_item_content {
		width: 90% !important;
	}
}

@media (max-width: 330px) {
	.right-sidebar-column .contentbox_item .contentbox_item_content {
		width: 85% !important;
	}
}

/*faqs*/
#faqs_module {
	margin-top: 20px;
}

#faqs_module .cms_list_subitem {
	margin-bottom: 40px;
}

#faqs_module .cms_list_subitem:last-of-type {
	margin-bottom: 0px;
}

/*resources*/
#moduleDocuments_module {
	margin-top: 20px;
}

#moduleDocuments_module .cms_description {
	margin-bottom: 20px;
}

#moduleDocuments_module .cms_description:last-of-type {
	margin-bottom: 0px;
}

/*testimonials*/
#testimonials_module {
	margin-top: 20px;
}

/*events*/
#events_module {
	margin-top: 20px;
}

.fc-event-container a {
	padding: 4px 0;
}

.fc-event-container a:hover {
	background-color: #d2e7d3 !important;
}

.fc-event-container a:active {
	background-color: transparent !important;
	color: #a3afa3 !important;
}


/* Slideshow */
.hero-caption-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	max-width: 75%;
	min-width: 70%;
	padding: 30px;
	float: right;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, #0d4033), to(transparent));
	background-image: linear-gradient(270deg, #0d4033 50%, transparent);
	text-align: center;
}

.hero-title {
	font-family: Roboto;
	color: #fff;
	font-size: 2.75rem;
	line-height: 3.5rem;
	font-weight: 400;
	text-transform: uppercase;
	text-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}

.hero-caption-p {
	max-width: 550px;
	color: #fff;
	font-size: 1.15rem;
	line-height: 1.55rem;
	font-weight: 400;
	text-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}

.hero-caption-p p {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	color: inherit;
	font-weight: inherit;
}

.hero-learn-more {
	display: inline-block;
	margin-top: 14px;
	padding: 0.25rem 2.25rem 0.25rem 1rem;
	background-color: #fff;
	background-image: url("/assets/images/arrow-green.png");
	background-position: 92% 50%;
	background-repeat: no-repeat;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #00693c;
	font-size: 18px;
	font-weight: 400;
	text-decoration: none;
	display: inline-block;
	margin-right: auto;
	margin-left: auto;
}

.posts-blocks-date-filter-button.primary {
	margin-top: 10px;
}

.posts-node .post-comment-author {
	font-size: 0.825rem;
}

@media (max-width: 1199px) {
	.hero-caption-div {
		padding-right: 10px;
	}
}

@media (max-width: 991px) {
	.hero-caption-div {
		max-width: 80%;
		min-width: 60%;
		padding-right: 30px;
	}

	.hero-title {
		margin-bottom: 0.5rem;
		font-size: 2rem;
		line-height: 2.25rem;
		text-align: right;
	}

	.hero-caption-p {
		font-size: 16px;
		text-align: right;
		float: right;
		margin-right: 0;
	}

	.hero-learn-more {
		float: right;
		margin-right: 0;
		margin-left: auto;
	}
}

@media (max-width: 767px) {
	.hero-caption-div {
		max-width: 767px;
		min-width: 0%;
	}

	.hero-title {
		font-size: 1.75rem;
		line-height: 2rem;
		max-width: 60%;
		float: right;
		margin-right: 0;
	}

	.hero-caption-p {
		max-width: 450px;
		font-size: 15px;
		line-height: 1.25rem;
	}
}

@media (max-width: 742px) {
	.hero-title {
		font-size: 1.75rem;
		line-height: 2rem;
		max-width: 75%;
	}
}

@media (max-width: 608px) {
	.hero-title {
		max-width: 93%;
	}
}

@media (max-width: 479px) {
	.hero-caption-div {
		padding: 20px;
	}

	.hero-title {
		font-size: 1.2rem;
		line-height: 1.5rem;
		max-width: 79%;
	}

	.hero-caption-p {
		font-size: 0.8rem;
		line-height: 1.125rem;
	}

	.w-slider-nav {
		padding-top: 22px;
	}
}

@media (max-width: 385px) {
	.hero-title {
		max-width: 100%;
	}
}


/* homepage */
.home-content-row {
	margin-left: -10px;
	margin-right: -10px;
	margin-bottom: 20px;
	display: flex;
}

.column-1,
.column-2,
.column-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.trio-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: 300px;
	padding-top: 3rem;
	padding-right: 1rem;
	padding-left: 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6))), url("https://uploads-ssl.webflow.com/5c376f5…/5c38c4c…_our-company_500x333.jpg");
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("https://uploads-ssl.webflow.com/5c376f5…/5c38c4c…_our-company_500x333.jpg");
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	width: 100%;
}

.cb-title {
	margin-bottom: 0.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid transparent;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #fff;
	font-size: 2rem;
	text-decoration: none;
	text-transform: uppercase;
	font-family: Arial, sans-serif;
	text-align: center;
	width: 100%;
}

.cb-title a {
	font-size: inherit;
	line-height: 30px;
	font-family: inherit;
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
	bottom: 0px;
	box-shadow: none;
}

.cb-title a:hover {
	border-bottom-color: transparent;
	color: hsla(0, 0%, 100%, 0.75);
}

.cb-text {
	margin-bottom: 1rem;
	color: #fff;
	font-size: 0.85rem;
	line-height: 1.25rem;
	text-align: center;
	width: 100%;
}

.cb-text p {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	color: inherit;
	font-weight: inherit;
	margin: inherit;
}

.cb-text li,
.cb-text a,
.cb-text h1,
.cb-text h2,
.cb-text h3,
.cb-text h4,
.cb-text h5,
.cb-text h6 {
	color: #fff;
	font-weight: normal;
}

.partners-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	min-height: 300px;
	padding-right: 1rem;
	padding-left: 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6))), url("../images/bw_combo.png");
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../images/bw_combo.png");
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	width: 100%;
	overflow: hidden;
}

@media (max-width: 991px) {

	.home-content-row {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.trio-div {
		height: auto;
		padding-top: 1.5rem;
		padding-bottom: 1rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.cb-title {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		line-height: 2rem;
		text-align: center;
	}

	.cb-title a {
		line-height: 2rem;
		text-align: center;
	}

	.column-2,
	.column-3,
	.column-1 {
		display: flex;
	}
}

@media (max-width: 767px) {
	.home-content-row {
		margin-bottom: 10px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		margin-left: 0px;
		margin-right: 0px;
		min-height: auto;
	}

	.column-1 {
		margin-bottom: 0.25rem;
		padding-bottom: 0.25rem;
		display: block;
	}

	.column-2 {
		margin-bottom: 0.25rem;
		padding-bottom: 0.25rem;
		display: block;
	}

	.column-3 {
		display: block;
	}

	.trio-div {
		width: 100%;
		padding-bottom: 1rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		display: block;
		min-height: auto;
	}

	.cb-title {
		height: auto;
		padding-top: 0.5rem;
	}

	.cb-text {
		width: 100%;
	}

	.partners-div {
		margin-top: 1rem;
	}
}

@media (max-width: 479px) {
	.cb-title {
		height: auto;
	}

	.partners-div {
		height: auto;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.partners-div table tr td {
		display: block;
		margin-bottom: 30px;
		width: 100% !important;
	}

	.partners-div table tr:last-child td:last-child {
		margin-bottom: 0px;
	}

	.partners-div table img {
		margin-bottom: 0px !important;
	}
}

@media all and (-ms-high-contrast: none) {
	.partners-div {
		padding-top: 40px;
	}
}

/* news content box */
#news_module.homepage_contentbox {
	color: #fff;
}

#news_module.homepage_contentbox .cms_list_item {
	width: 100%;
	margin-bottom: 1rem;
	padding: 0.35rem 1rem;
	background-color: rgba(13, 64, 51, 0.65);
}

#news_module.homepage_contentbox.no-abstract .cms_list_item {
	display: flex;
}

#news_module.homepage_contentbox .cms_list_item .cms_title h3 {
	text-align: left;
	margin-bottom: 0px;
	margin-top: 0px;
}

#news_module.homepage_contentbox.no-abstract .cms_list_item .cms_title h3 {
	padding-left: 1rem;
}

#news_module.homepage_contentbox .cms_list_item .cms_title h3 a,
#news_module.homepage_contentbox .cms_list_item .cms_title h3 {
	color: #fff;
	font-size: 0.85rem !important;
	line-height: 1.25rem !important;
	text-decoration: none;
}

#news_module.homepage_contentbox .cms_list_item .cms_date h3 {
	color: #fff;
	font-size: 0.85rem !important;
	line-height: 1.25rem;
	margin-bottom: 0px;
	margin-top: 0px;
	text-align: left;
}

#news_module.homepage_contentbox > a:last-child {
	min-width: 150px;
	color: #fff;
	text-decoration: none;
	font-size: 1rem;
	text-transform: uppercase;
	font-family: Arial, sans-serif;
	padding-left: 0.35rem;
	padding-right: 0.35rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	text-align: center;
	border: 1px solid #fff;
	display: inline-block;
}

#news_module.homepage_contentbox > a:last-child:hover {
	background-color: #fff;
	color: #0d4033;
}

#news_module.homepage_contentbox .cms_description {
	text-align: left;
	margin-top: 3px;
}

@media (max-width: 991px) {
	#news_module.homepage_contentbox.no-abstract .cms_list_item {
		display: block;
	}

	#news_module.homepage_contentbox .cms_list_item .cms_title h3,
	#news_module.homepage_contentbox.no-abstract .cms_list_item .cms_title h3 {
		padding-left: 0px;
	}
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Inside Page ******************/
/*************************************************/
@media screen and (max-width: 991px) {
	iframe[src*="youtube.com"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}
